import React from 'react'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation from '../../components/Infos/InfosNavigation'
import { StaticImage } from 'gatsby-plugin-image'

const Famille = () => {
  const metaTitle = 'Famille | Le Festif! de Baie-Saint-Paul'
  const metaDescription = ''
  const metaKeywords = 'festival, musique, évènement, spectacles, québec, charlevoix, baie-saint-paul, famille'

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader
        title="Programmation familiale"
      />
      <div className="container content pb-12">
        <div>

          {/* SPONSORS */}
          <div className="">
              <h2 className="!ml-4 !pl-0">Présentée par Télé-Québec</h2>
              <div className="max-w-[250px] py-4 ml-4">
                  <a href="https://www.telequebec.tv/">
                  <StaticImage src="../../images/partenaires/telequebec-lafrabriqueculturelle.png" alt="Logo Télé-Québec" imgClassName=""/>
                  </a>
              </div>
          </div>

          <a href='/infos/ruefestive#info_content' className='!no-underline'><h3 className='underline'>Rue Festive</h3></a>
          <p>Samedi le 20 juillet de midi à 18h</p>

          <a className='!no-underline'><h3>L'espace famille</h3></a>
          <p>Situé près du Parvis, l’espace famille permet aux enfants de se dégourdir les pattes dans plusieurs installations.</p>

          <h3 className='mb-5'>Ateliers et activités</h3>

          <div className="billetterie flex flex-wrap w-full">

            <div className="md:w-1/6 w-full text-center px-4 md:my-0 my-2"></div>

            {/* RUE DE LA TANNERIE */}
            <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
              <div className="bg-white rounded-3xl">
                <div className="bg-blue-wolf2024 rounded-t-3xl px-0 py-6 text-white">
                  <h3 className="leading-none mb-3">Vendredi<br/>19 juillet</h3>
                </div>
                <div className="text-blue-wolf2024 py-4 px-0">
                  <p className="mb-4 !text-blue-wolf2024 !px-9"><b>10h00 au Parvis Ici Musique</b><br/>Initiation aux percussions traditionnelles dans le cadre des Jams de l’arrière-pays - Les cuillères et les Os.</p>
                  <p className="mb-4 !text-blue-wolf2024 !px-9"><b>11h00 à 18h00 sur la rue Saint-Jean-Baptiste</b><br/>Expérience 360 de la Fabrique Culturelle - ANTICOSTI: S’immiscer dans la grandeur</p>
                </div>
              </div>
            </div>

            {/* RUE JEAN-TALON */}
            <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
              <div className="bg-white rounded-3xl">
                <div className="bg-green-wolf2024 rounded-t-3xl px-0 py-6 text-white">
                  <h3 className="leading-none mb-3">Samedi<br/>20 juillet</h3>
                </div>
                <div className="text-green-wolf2024 py-4 px-0">
                  <p className="mb-4 !text-green-wolf2024  !px-9"><b>11h00 à 18h00 à l’Espace Famille</b><br/>BDthèque mobile Québec BD</p>
                  <p className="mb-4 !text-green-wolf2024  !px-9"><b>10h00 à 12h00 à l’Espace Famille</b><br/>Atelier de fabrication de carillons de la Maison des Métiers d’art du Québec</p>
                  <p className="mb-4 !text-green-wolf2024  !px-9"><b>11h30 au Parvis Ici Musique</b><br/>Initiation aux percussions traditionnelles dans le cadre des Jams de l’arrière-pays - Les cuillères et les Os</p>
                  <p className="mb-4 !text-green-wolf2024  !px-9"><b>11h00 à 18h00 sur la rue Saint-Jean-Baptiste</b><br/>Expérience 360 de la Fabrique Culturelle - ANTICOSTI: S’immiscer dans la grandeur</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='flex w-full pt-12'>
          <div className='w-1/2 md:w-full px-6'>
            <StaticImage src="../../images/famille1.jpg" className="rounded-3xl" />
          </div>
          <div className='w-1/2 md:w-full px-6'>
            <StaticImage src="../../images/famille2.jpg" className="rounded-3xl" />
          </div>
        </div>
        <div className='flex pt-12'>
          <div className='w-1/2 md:w-full px-6'>
            <StaticImage src="../../images/famille3.jpg" className="rounded-3xl" />
          </div>
          <div className='w-1/2 md:w-full px-6'>
            <StaticImage src="../../images/famille4.png" className="rounded-3xl" />
          </div>
        </div>

        {/* LQ BANNER */}
        <div className="w-full center py-8 px-6">
          <div className="max-w-[728px] max-h-[90px] mx-auto">
            <a className="" href="https://www.lafabriqueculturelle.tv/10ans">
              <StaticImage src="../../images/partenaires/telequebec-banner.jpg"/>
            </a>
          </div>
        </div>

        <div>
          <p className='mb-4'><b>La Tournée des Gonflables</b></p>
          <p>Propulsé·es par le <a href="https://www.google.com/url?q=https://muralfestival.com/fr/&sa=D&source=docs&ust=1718929587494194&usg=AOvVaw3DcNGhGzUZn753T0y3PKTb">Festival d’Art Urbain MURAL</a>, cinq artistes de talent ont créé des sculptures gonflables de près de 12 pieds de haut. Impressionnants et ludiques, ces personnages-sculptures ajouteront de la vie à la rue Saint-Jean-Baptiste tout au long du Festif! Avec la contribution de <a href="https://www.google.com/url?q=https://www.instagram.com/dalkhafine/&sa=D&source=docs&ust=1718929587494579&usg=AOvVaw2C8s5TuQy50N4hx4WrwWk2">Dalkhafine</a>, <a href="https://www.google.com/url?q=https://www.instagram.com/chien.champion/&sa=D&source=docs&ust=1718929587494870&usg=AOvVaw3yWgdONxBGwKk7gEQ2osZ0">Chien Champion</a>, <a href="https://www.google.com/url?q=https://www.instagram.com/lacharbonne/&sa=D&source=docs&ust=1718929587495123&usg=AOvVaw1Oba1cDRs-pyTsvkfdrMYs">LaCharbonne</a>, <a href="https://www.google.com/url?q=https://www.instagram.com/melissalikessushi/&sa=D&source=docs&ust=1718929587495400&usg=AOvVaw015YknSuzaMkwn-IDVx5ym">Melissalikessushi</a> et <a href="https://www.google.com/url?q=https://www.instagram.com/ponymtl/&sa=D&source=docs&ust=1718929587495645&usg=AOvVaw3vb3x86QGrI3QwUjLI9IV1">Pony</a>.</p>
          <div className='text-center'>
            <StaticImage className="max-w-[300px]" src="../../images/partenaires/tourneegonflables.png" />
          </div>
        </div>

      </div>
    </Wolflayout>
  )
}

export default Famille
